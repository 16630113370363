* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    overflow-x: hidden;
    scroll-padding-top: 75px;
}

a {
    text-decoration: none;
}

#subscriberFormInput::placeholder {
    color: #000;
    opacity: 1;
}
.rightLabel1 + *:before {
    content: "*";
    color: red;
    position: absolute;
    top: 11px;
    right: 88px;
}
@media only screen and (max-width: 960px) {
    .rightLabel1 + *:before {
        right: 94px;
    }
}
@media only screen and (max-width: 900px) {
    .rightLabel1 + *:before {
        right: 88px;
    }
}
.rightLabel1:active + *:before {
    display: none;
}
.rightLabel1:valid + *:before {
    display: none;
}

.rightLabel2 + *:before {
    content: "*";
    color: red;
    position: absolute;
    top: 11px;
    right: 70px;
}
@media only screen and (max-width: 960px) {
    .rightLabel2 + *:before {
        right: 74px;
    }
}
@media only screen and (max-width: 900px) {
    .rightLabel2 + *:before {
        right: 66px;
    }
}
.rightLabel2:active + *:before {
    display: none;
}
.rightLabel2:valid + *:before {
    display: none;
}
.rightLabel3 + *:before {
    content: "*";
    color: red;
    position: absolute;
    top: 11px;
    right: 70px;
}
@media only screen and (max-width: 960px) {
    .rightLabel3 + *:before {
        right: 74px;
    }
}
@media only screen and (max-width: 900px) {
    .rightLabel3 + *:before {
        right: 68px;
    }
}
.rightLabel3:active + *:before {
    display: none;
}
.rightLabel3:valid + *:before {
    display: none;
}
.leftLabel1 + *:before {
    content: "*";
    color: red;
    position: absolute;
    top: 6px;
    left: 85px;
}
@media only screen and (max-width: 960px) {
    .leftLabel1 + *:before {
        left: 94px;
    }
}
@media only screen and (max-width: 900px) {
    .leftLabel1 + *:before {
        left: 85px;
    }
}
.leftLabel1:active + *:before {
    display: none;
}
.leftLabel1:valid + *:before {
    display: none;
}
.leftLabel2 + *:before {
    content: "*";
    color: red;
    position: absolute;
    top: 6px;
    left: 85px;
}
@media only screen and (max-width: 960px) {
    .leftLabel2 + *:before {
        left: 92px;
    }
}
@media only screen and (max-width: 900px) {
    .leftLabel2 + *:before {
        left: 85px;
    }
}
.leftLabel2:active + *:before {
    display: none;
}
.leftLabel2:valid + *:before {
    display: none;
}
.leftLabel3 + *:before {
    content: "*";
    color: red;
    position: absolute;
    top: 6px;
    left: 82px;
}
@media only screen and (max-width: 960px) {
    .leftLabel3 + *:before {
        left: 88px;
    }
}
@media only screen and (max-width: 900px) {
    .leftLabel3 + *:before {
        left: 83px;
    }
}
.leftLabel3:active + *:before {
    display: none;
}
.leftLabel3:valid + *:before {
    display: none;
}
body {
    padding-right: 0 !important;
}

#root {
    overflow-x: hidden;
}

button:focus {
    outline: none;
}

ul.MuiList-root {
    max-height: 300px !important;
}
#subscribePopUp {
    background-color: #0aaad266;
}
#subscribeContent {
    padding: 0px !important;
    overflow: auto;
}
/* .MuiDialog-container {
  background-color: #0aaad266;
} */
.MuiDialog-paper {
    border-radius: 22px !important;
}
/* .MuiDialogContent-root {
  padding: 0px !important;
} */
